.hidden {
  display: none;
}

#search {
  color: #fff;
}

@font-face {
  font-family: Altima;
  src: url("altima.29fa46cf.woff") format("woff");
}

@font-face {
  font-family: BossBattle;
  src: url("bossbattle.e8e6a01c.woff") format("woff");
}

.map-holder {
  height: 350px;
  width: 350px;
  position: relative;
}

.map-holder h3 {
  text-align: center;
  position: absolute;
  top: -15px;
  left: 0;
}

.map-holder img {
  width: 350px;
  height: 350px;
  object-fit: contain;
  object-position: center center;
  opacity: 0;
  animation-name: FadeInOut;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.map-holder img:nth-of-type(1) {
  animation-delay: 7.5s;
}

.map-holder img:nth-of-type(2) {
  animation-delay: 5s;
}

.map-holder img:nth-of-type(3) {
  animation-delay: 2.5s;
}

a {
  color: unset;
  text-decoration: none;
}

.fuckingaroundwithfirebaseshit {
  background: #fff;
}

select, select option, option:disabled {
  color: #000;
}

h1 {
  color: #fff;
  -webkit-text-stroke: .1px #000;
  font-family: BossBattle;
  font-size: 30px;
}

.top-bar {
  margin-bottom: 10px;
}

h2 {
  color: #fff;
}

#app {
  text-align: left;
  color: #2c3e50;
  font-size: 20px;
}

@media (min-width: 500px) {
  #app {
    font-size: 16px;
  }
}

html {
  background: #48411b;
}

html:after {
  width: 100vw;
  height: 100vh;
  content: "";
  z-index: -1;
  background: url("bg-map.631d12fe.jpeg") center / cover no-repeat;
  position: fixed;
  top: 0;
  left: 0;
}

.evasion p {
  margin: 0 0 7px;
}

.hit-chances h3 {
  margin-top: 0;
  margin-bottom: 7px;
}

.hit-chances p {
  margin-top: 0;
  margin-bottom: 5px;
}

.stats {
  text-align: center;
  background: #0c36501a;
  border: 2px solid #565341;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
  font-size: 14px;
  transition: all .3s;
  position: relative;
  box-shadow: 20px 38px 34px -26px #0003;
}

.stats:hover {
  box-shadow: 2px 8px 4px -6px #0000004d;
}

.stats .stat {
  z-index: 2;
  position: relative;
}

.stats .hasToolTip {
  font-weight: bold;
}

.stats .goodBonus {
  color: #0f0;
}

.unit-skills {
  background: #0c36501a;
  border: 2px solid #565341;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px 0;
  font-size: 18px;
}

.unit-skills.target-faith p {
  margin: 0;
}

.unit-skills.target-faith input {
  margin: 0;
  padding: 0;
}

.unit-innates {
  background: #0c36501a;
  border: 2px solid #565341;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px 0;
  font-size: 14px;
}

.unit-innates .innate {
  letter-spacing: .4px;
  margin: 0 5px;
  font-family: Altima;
  font-size: 30px;
  line-height: 30px;
  display: flex;
}

.unit-innates .innate .innate-text {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px #00000080;
  margin-left: 10px;
}

.unit-innates .innate .ability-icon {
  width: 40px;
  height: 30px;
  object-fit: contain;
  padding: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.unit-equipment {
  background: #0c36501a;
  border: 2px solid #565341;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px 0;
  font-size: 14px;
}

.unit-equipment .equipment {
  letter-spacing: .4px;
  margin: 0 5px;
  font-family: Altima;
  font-size: 30px;
  line-height: 40px;
  display: flex;
}

.unit-equipment .equipment.unarmed.monster {
  display: flex;
}

.unit-equipment .equipment.unarmed.hasWeapon {
  display: none;
}

.unit-equipment .equipment.unarmed.hasWeapon.showUnarmed {
  display: flex;
}

.unit-equipment .equipment .equipment-text {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px #00000080;
  margin-left: 10px;
}

.unit-equipment .equipment .equipment-text.showUnarmed {
  text-decoration: line-through red;
}

.unit-equipment .equipment .ability-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  padding: 7px;
  position: relative;
}

.unit-equipment .equipment .ability-icon.cursor-pointer:hover {
  background: red;
}

.unit-equipment .equipment .gear-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  padding: 5px;
}

button {
  background: #fff;
  border: 1px solid #2c3e50;
  margin-right: 3px;
  padding: 5px 10px;
}

button.hiding {
  color: #fff;
  background: #1c6ea4;
}

.container {
  width: 98%;
  max-width: 1500px;
  border-radius: 8px;
  margin: 0 auto;
}

li {
  font-size: 1.5rem;
  list-style: none;
}

input {
  border-radius: 4px;
  margin-top: 5px;
  padding: 10px 20px;
  font-size: 14px;
}

.teams.hidered .red, .teams.hideblue .blue, .teams.hidegreen .green, .teams.hideyellow .yellow, .teams.hidewhite .white, .teams.hideblack .black, .teams.hidebrown .brown, .teams.hidepurple .purple, .teams.hidechamp .champion {
  display: none;
}

@media (min-width: 700px) {
  .top-bar {
    justify-content: space-between;
    display: flex;
  }
}

.top-bar .snubs, .top-bar .entries {
  color: #fff;
  background: url("bg.377f5627.gif");
  border: 2px solid #565341;
  border-radius: 10px;
  padding: 5px 10px;
  display: inline-block;
  position: relative;
}

.top-bar .buttons {
  background: url("bg.377f5627.gif");
  border: 2px solid #565341;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px;
  display: inline-block;
  position: relative;
}

@media (min-width: 700px) {
  .top-bar .buttons {
    margin-bottom: 0;
  }
}

.bold {
  color: #23ff33;
}

.search-box .team {
  min-height: 585px;
  padding-top: 15px;
}

.search-box .team .holder {
  justify-content: flex-start;
}

.search-box .team .holder .unit {
  margin-bottom: 1%;
  margin-right: 1%;
}

.team {
  background: #4c3b04b3;
  border: 2px solid #565341;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 0 10px 10px;
  position: relative;
}

.team > h3 {
  color: #fff;
  -webkit-text-stroke: .1px #000;
  z-index: 2;
  margin: 10px 0;
  font-family: BossBattle;
  font-size: 30px;
  position: relative;
}

h3, h1, h2 {
  text-align: center;
}

.status {
  margin-bottom: 7px;
}

.status h3 {
  margin: 0 0 5px;
}

.status div {
  text-align: center;
}

.hasToolTip {
  position: relative;
}

.hasToolTip:hover .toolTipSide, .hasToolTip.visible .toolTipSide {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  transition: all .2s;
}

.toolTipSide {
  text-align: left;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  max-width: 400px;
  width: 400px;
  z-index: 1000;
  background: url("bg.377f5627.gif");
  border: 1px solid #fff;
  border-radius: 6px;
  margin-left: 15px;
  padding: 20px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
}

@media (max-width: 1199px) {
  .toolTipSide {
    display: none !important;
  }
}

.champ .toolTipSide, .final .toolTipSide {
  left: auto;
  right: 110%;
}

.champ .toolTipSide:before, .champ .toolTipSide:after, .final .toolTipSide:before, .final .toolTipSide:after {
  left: 100%;
  right: auto;
  transform: rotate(180deg);
}

@media (max-width: 200px) {
  .toolTipSide {
    width: 100%;
    max-width: 100%;
    border: none;
    border-top: 1px solid #fff;
    border-radius: 0;
    margin-left: 0;
    display: block;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .toolTipSide:before, .toolTipSide:after {
    display: none;
  }
}

.toolTipSide:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-right: 10px solid #fff;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.toolTipSide:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  border-right: 8px solid #a19a80;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.toolTipSide p {
  text-shadow: 1px 1px 1px #00000080;
  margin: 0 0 5px;
  font-family: Altima;
  font-size: 30px;
}

.toolTipSide h5 {
  display: none;
}

.hasToolTip {
  position: relative;
}

.hasToolTip:hover .toolTip, .hasToolTip.visible .toolTip {
  visibility: visible;
  opacity: .8;
  z-index: 999;
  transition: all .2s;
}

.toolTip {
  text-align: left;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  max-width: 400px;
  width: 400px;
  z-index: 1000;
  background: #000;
  border: 1px solid #fff;
  border-radius: 6px;
  margin-left: 15px;
  padding: 20px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
}

@media (max-width: 2500px) {
  .toolTip {
    width: 100%;
    max-width: 100%;
    border: none;
    border-top: 1px solid #fff;
    border-radius: 0;
    margin-left: 0;
    display: block;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .toolTip:before, .toolTip:after {
    display: none;
  }
}

.toolTip:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-right: 10px solid #fff;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.toolTip:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  border-right: 8px solid #000;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.toolTip p {
  text-shadow: 1px 1px 1px #00000080;
  margin: 0 0 5px;
  font-family: Altima;
  font-size: 30px;
}

.toolTip h5 {
  display: none;
}

.damage-number {
  font-size: 25px;
}

.aventail {
  font-family: Avenir;
  font-size: 18px;
  line-height: 23px;
}

.faith-gun {
  display: inline-block;
}

.toolTipSide .image-stats {
  text-align: left;
  justify-content: space-between;
  display: flex;
}

.toolTipSide .image-stats .image-holder {
  width: 78px;
  display: flex;
}

.toolTipSide .image-stats .image-holder > img {
  height: 110px;
  margin-top: 15px;
}

.toolTipSide .image-stats .image-holder .teamStars img, .toolTipSide .image-stats .image-holder .enemyStars img {
  border: none !important;
  border-radius: 0 !important;
}

.toolTipSide .image-stats .image-holder img {
  border: 7px inset #1c6ea4;
  border-radius: 16px;
}

.toolTipSide .image-stats .name-and-stats {
  width: calc(100% - 150px);
}

.toolTipSide .unit-equipment {
  font-weight: 400;
}

.red .holder .unit .image-stats .image-holder img, .red-team .unit .image-stats .image-holder img {
  border: 7px inset #a41c1c;
}

.blue .holder .unit .image-stats .image-holder img, .blue-team .unit .image-stats .image-holder img {
  border: 7px inset #1c1ea4;
}

.green .holder .unit .image-stats .image-holder img, .green-team .unit .image-stats .image-holder img {
  border: 7px inset #33a41c;
}

.yellow .holder .unit .image-stats .image-holder img, .yellow-team .unit .image-stats .image-holder img {
  border: 7px inset #a49d1c;
}

.white .holder .unit .image-stats .image-holder img, .white-team .unit .image-stats .image-holder img {
  border: 7px inset #fff;
}

.black .holder .unit .image-stats .image-holder img, .black-team .unit .image-stats .image-holder img {
  border: 7px inset #000;
}

.purple .holder .unit .image-stats .image-holder img, .purple-team .unit .image-stats .image-holder img {
  border: 7px inset #7b1ca4;
}

.brown .holder .unit .image-stats .image-holder img, .brown-team .unit .image-stats .image-holder img {
  border: 7px inset #914808;
}

.champion .holder .unit .image-stats .image-holder img, .champ-team .unit .image-stats .image-holder img {
  border: 7px inset #fe8c28;
}

@media (min-width: 700px) {
  .holder {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }
}

.holder .unit {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  .holder .unit {
    width: 48%;
    margin-bottom: 0;
  }
}

@media (min-width: 1300px) {
  .holder .unit {
    width: 24%;
  }
}

.holder .unit .bg-and-holder {
  text-align: center;
  background: url("bg.377f5627.gif");
  border: 2px solid #565341;
  border-radius: 10px;
  padding: 20px;
}

@media (min-width: 1500px) {
  .holder .unit .image-stats {
    text-align: left;
    justify-content: space-between;
    display: flex;
  }
}

@media (min-width: 1500px) {
  .holder .unit .image-stats .image-holder {
    width: 78px;
  }
}

.holder .unit .image-stats .image-holder img {
  border: 7px inset #1c6ea4;
  border-radius: 16px;
}

@media (min-width: 1500px) {
  .holder .unit .image-stats .name-and-stats {
    width: calc(100% - 90px);
  }
}

.holder .unit.extra-data {
  width: 100%;
  text-align: center;
  background: none;
  display: inline-block;
}

.formholder button {
  margin-left: 10px;
  padding: 5px 10px;
  font-weight: bold;
}

/*# sourceMappingURL=index.14002d19.css.map */
