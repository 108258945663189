.hidden {
    display: none;
}

#search {
    color: #fff;
}

@font-face {
    font-family: 'Altima';
    src:  url('../fonts/altima.woff') format('woff');
  }

  @font-face {
    font-family: 'BossBattle';
    src:  url('../fonts/bossbattle.woff') format('woff');
  }

  .map-holder {
      position: relative; 
      height: 350px;
      width: 350px;

      h3 {
          position: absolute;
          top: -15px;
          left: 0;
          text-align: center;
      }

      img {
          width: 350px;
          height: 350px;
          object-fit: contain;
          object-position: center center;
          position: absolute;
          top: 0;
          left: 0;
      }


      img {
        animation-name: FadeInOut;
        animation-iteration-count: infinite;
        animation-duration: 10s;
        opacity: 0;
        }

      @keyframes FadeInOut {
        0% {
            opacity:1;
        }
        17% {
            opacity:1;
        }
        25% {
            opacity:0;
        }
        92% {
            opacity:0;
        }
        100% {
            opacity:1;
        }
        }

        img:nth-of-type(1) {
        animation-delay: 7.5s;
        }
        img:nth-of-type(2) {
        animation-delay: 5s;
        }
        img:nth-of-type(3) {
        animation-delay: 2.5s;
        }
        img:nth-of-type(4) {
        }
  }


  a {
      color: unset;
      text-decoration: none;
  }
  .fuckingaroundwithfirebaseshit {
      background: #fff;
  }

  select {
      color: #000;

      option {
        color: #000;
      }
  }

  option:disabled {
      color: #000;
  }
  h1 {
      font-family: "BossBattle";
        color: #fff;
        -webkit-text-stroke: .1px #000;
        font-size: 30px;
  }


  .top-bar {
      margin-bottom: 10px;
  }

  h2 {
      color: #fff;
  }

#app {
  text-align: left;
  color: #2c3e50;
  font-size: 20px;
  @media all and (min-width: 500px) {
      font-size: 16px;
  }
}

html {
    background: #48411b;

    &:after {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: url(../images/bg-map.jpeg) center center / cover no-repeat;
        content: "";
        z-index: -1;
        top: 0;
        left: 0;
    }
    
}


.evasion {
    p {
        margin: 0 0 7px 0;
    }
}
.hit-chances {


    h3 {
        margin-top: 0;
        margin-bottom: 7px;
    }

    p {
        margin-bottom: 5px;
        margin-top: 0;
    }
}

.stats {
    margin: 10px 0;
    padding: 10px 10px;
    border: 2px solid #565341;
    position: relative;
    border-radius: 10px;
    box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
    transition: all .3s;
    text-align: center;
    font-size: 14px;
    background: rgba(12, 54, 80,.1);


    &:hover{
        box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
    }  

    .stat {
        position: relative;
        z-index: 2;
    }


    .hasToolTip {
        font-weight: bold;
    }

    .goodBonus {
        color: #00ff00;
    }
}


.unit-skills {
    font-size: 18px;
    margin: 10px 0;
    padding: 10px 0;
    border: 2px solid #565341;
    border-radius: 10px;
    background: rgba(12, 54, 80,.1);

    &.target-faith {
        p {
            margin: 0;
        }
        input {
            padding: 0;
            margin: 0;
        }
    }
}

.unit-innates {
    font-size: 14px;
    margin: 10px 0;
    padding: 10px 0;
    border: 2px solid #565341;
    border-radius: 10px;
    background: rgba(12, 54, 80,.1);


    .innate {
        display: flex;
        margin: 0 5px;
        font-family: "Altima";
        font-size: 30px;
        line-height: 30px;
        letter-spacing: .4px;
        
        

        .innate-text {
            margin-left: 10px;
            -webkit-font-smoothing: antialiased;
            text-shadow: 1px 1px 1px rgba(0,0,0,.5);
        }

        .ability-icon {
            width: 40px;
            height: 30px;
            object-fit: contain;
            padding: 4px;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}


.unit-equipment {
    font-size: 14px;
    margin: 10px 0;
    padding: 10px 0;
    border: 2px solid #565341;
    border-radius: 10px;
    background: rgba(12, 54, 80,.1);


    .equipment {
        display: flex;
        margin: 0 5px;
        font-family: "Altima";
        font-size: 30px;
        line-height: 40px;
        letter-spacing: .4px;

        &.unarmed.monster {
            display: flex;
        }

        &.unarmed.hasWeapon {
            display: none;

            &.showUnarmed {
                display: flex;
            }
        }


        
        

        .equipment-text {
            margin-left: 10px;
            -webkit-font-smoothing: antialiased;
            text-shadow: 1px 1px 1px rgba(0,0,0,.5);

            &.showUnarmed {
                text-decoration: line-through red;
            }
        }

        .ability-icon {
            width: 40px;
            height: 40px;
            object-fit: contain;
            padding: 7px;
            position: relative;

            &.cursor-pointer {
                &:hover {
                    background: #ff0000
                }
            }
        }

        .gear-icon {
            width: 40px;
            height: 40px;
            object-fit: contain;
            padding: 5px;
        }
    }
}

button {
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #2c3e50;
    margin-right: 3px;
    &.hiding {
        background: #1C6EA4;
        color: #fff;
    }
}
.container {

  width: 98%;
  max-width: 1500px;
  margin: 0 auto;
  border-radius: 8px;
}
li {
  font-size: 1.5rem;
  list-style: none;
}

input {
  margin-top: 5px;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.teams {
    &.hidered .red {
        display: none;
    }
    &.hideblue .blue {
        display: none;
    }
    &.hidegreen .green {
        display: none;
    }
    &.hideyellow .yellow {
        display: none;
    }
    &.hidewhite .white {
        display: none;
    }
    &.hideblack .black {
        display: none;
    }
    &.hidebrown .brown {
        display: none;
    }
    &.hidepurple .purple {
        display: none;
    }
    &.hidechamp .champion {
        display: none;
    }
}


.top-bar {
    @media all and (min-width: 700px) {
        display: flex;
        justify-content: space-between;
    }

.snubs,
.entries {
    background: url(../images/bg.gif);
    border: 2px solid #565341;
    border-radius: 10px;
    position: relative;
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
}

.buttons {
    background: url(../images/bg.gif);
    border: 2px solid #565341;
    border-radius: 10px;
    position: relative;
    padding: 5px;
    display: inline-block;
    margin-bottom: 10px;
    @media all and (min-width: 700px) {
        margin-bottom: 0;
    }
}
}

.bold {
    color: #23ff33;
}

.search-box {
    .team {
        padding-top: 15px;
        min-height: 585px;

        .holder {
            justify-content: flex-start;

            .unit {
                margin-right: 1%;
                margin-bottom: 1%;
            }
        }
    }
}


.team {
    margin-bottom: 25px;

    
    border: 2px solid #565341;
    border-radius: 10px;
    position: relative;
    padding: 0 10px 10px 10px;
    background: rgba(76, 59, 4, .7);

    



    >h3 {
        font-family: "BossBattle";
        color: #fff;
        -webkit-text-stroke: .1px #000;
        font-size: 30px;
        position: relative;
        z-index: 2;
        margin: 10px 0;
    }
}
h3 {
    text-align: center;
}

h1 {
    text-align: center;
}

h2 {
    text-align: center;
}

.status {

    margin-bottom: 7px;

    h3 {
        margin: 0 0 5px 0;
    }

    div {
        text-align: center;
    }
}

/* Tooltip container */
.hasToolTip {
    position: relative;

    &:hover .toolTipSide,
    &.visible .toolTipSide {
        visibility: visible;
        opacity: 1;
        z-index: 999;
        transition: all .2s;
    }
}

.toolTipSide {
    @media all and (max-width: 1199px) {
        display: none !important;
    }
    position: absolute;
    text-align: left;
    color: #FFFFFF;
    background: url(../images/bg.gif);
    line-height: 20px;
    visibility: hidden;
    border-radius: 6px;
    left: 110%;
    margin-left: 15px;
    border: 1px solid #fff;
    padding: 20px;
    opacity: 0;
    max-width: 400px;
    width: 400px;
    top: 50%;
    z-index: 1000;
    transform: translatey(-50%);

    .champ &,
    .final & {
        left: auto;
        right: 110%;

        &:before,
        &:after  {
            left: 100%;
            right: auto;
            transform:rotate(180deg);
        }

    }
    @media all and (max-width:200px) {
        position: fixed;
        bottom:0px;
        left: 0px;
        transform: none;
        display: block;
        top: auto;
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
        border: none;
        border-top: 1px solid #fff;
        border-radius: 0;

        &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -10px;
        width: 0; height: 0;
        border-right: 10px solid #FFFFFF;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -8px;
        width: 0; height: 0;
        border-right: 8px solid #a19a80;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }

    p {
        font-size: 30px;
        margin: 0 0 5px 0;
        font-family: "Altima";
        text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    }

    h5 {
        display: none;
    }
}


/* Tooltip container */
.hasToolTip {
    position: relative;

    &:hover .toolTip,
    &.visible .toolTip {
        visibility: visible;
        opacity: 0.8;
        z-index: 999;
        transition: all .2s;
    }
}



.toolTip {
    position: absolute;
    text-align: left;
    color: #FFFFFF;
    background: #000000;
    line-height: 20px;
    visibility: hidden;
    border-radius: 6px;
    left: 110%;
    margin-left: 15px;
    border: 1px solid #fff;
    padding: 20px;
    opacity: 0;
    max-width: 400px;
    width: 400px;
    top: 50%;
    z-index: 1000;
    transform: translatey(-50%);
    @media all and (max-width:2500px) {
        position: fixed;
        bottom:0px;
        left: 0px;
        transform: none;
        display: block;
        top: auto;
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
        border: none;
        border-top: 1px solid #fff;
        border-radius: 0;

        &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -10px;
        width: 0; height: 0;
        border-right: 10px solid #FFFFFF;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -8px;
        width: 0; height: 0;
        border-right: 8px solid #000000;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }

    p {
        font-size: 30px;
        margin: 0 0 5px 0;
        font-family: "Altima";
        text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    }

    h5 {
        display: none;
    }
}

.damage-number {
    font-size: 25px;
}

.aventail {
    font-family: Avenir;
    font-size: 18px;
    line-height: 23px;

}
.faith-gun {
    display: inline-block;
}


.toolTipSide {
    .image-stats {
            display: flex;
            text-align: left;
            justify-content: space-between;
    
    
    

        .image-holder {
                width: 78px;
                display: flex;

                >img {
                    height: 110px;
                    margin-top: 15px;
                }

                .teamStars {
                    img {
                        border: none !important;
                        border-radius: 0 !important;
                    }

                }

                .enemyStars {

                    img {
                        border: none !important;
                        border-radius: 0 !important;
                    }
                }
            
            img {
                border: 7px inset #1C6EA4;
                border-radius: 16px;
            }
        }


        .name-and-stats {
                width: calc( 100% - 150px);
        }
    }

    .unit-equipment {
        font-weight: 400;
    }
}

.red .holder .unit .image-stats .image-holder img,
.red-team .unit .image-stats .image-holder img {
    border: 7px inset #a41c1c;
}

.blue .holder .unit .image-stats .image-holder img,
.blue-team .unit .image-stats .image-holder img {
    border: 7px inset #1c1ea4;
}

.green .holder .unit .image-stats .image-holder img,
.green-team .unit .image-stats .image-holder img {
    border: 7px inset #33a41c;
}

.yellow .holder .unit .image-stats .image-holder img,
.yellow-team .unit .image-stats .image-holder img {
    border: 7px inset #a49d1c;
}

.white .holder .unit .image-stats .image-holder img,
.white-team .unit .image-stats .image-holder img {
    border: 7px inset #FFFFFF;
}

.black .holder .unit .image-stats .image-holder img,
.black-team .unit .image-stats .image-holder img {
    border: 7px inset #000000;
}

.purple .holder .unit .image-stats .image-holder img,
.purple-team .unit .image-stats .image-holder img {
    border: 7px inset #7b1ca4;
}

.brown .holder .unit .image-stats .image-holder img,
.brown-team .unit .image-stats .image-holder img {
    border: 7px inset #914808;
}

.champion .holder .unit .image-stats .image-holder img,
.champ-team .unit .image-stats .image-holder img {
    border: 7px inset #fe8c28;
}


.holder {


    @media all and (min-width: 700px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .unit {
        width: 100%;
        margin-bottom: 20px;
        @media all and (min-width: 600px) {
            margin-bottom: 0;
            width: 48%
        }
        @media all and (min-width: 1300px) {
            width: 24%
        }

        .bg-and-holder {
            background: url(../images/bg.gif);
            border: 2px solid #565341;
            border-radius: 10px;
            padding: 20px;
            text-align: center;
        }

        .image-stats {
            @media all and (min-width: 1500px) {
                display: flex;
                text-align: left;
                justify-content: space-between;
            }
        
        

            .image-holder {
                @media all and (min-width: 1500px) {
                    width: 78px;
                }
                
                img {
                    border: 7px inset #1C6EA4;
                    border-radius: 16px;
                }
            }
    

            .name-and-stats {
                @media all and (min-width: 1500px) {
                    width: calc( 100% - 90px);
                }
            }
        }

        &.extra-data {
            display: inline-block;
            width: 100%;
            background: none;
            text-align: center;
        }
    }
}

.formholder {

    button {
        padding: 5px 10px;
        font-weight: bold;
        margin-left: 10px;
    }
}